import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import React from 'react'


export default function ResultsThumb({ data }) {
  const { award, narrative, category } = data;

  const backgroundImage = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "dreyer-law-rear-end-accident-snow-2.jpg"}) {
        id
        childImageSharp {
          gatsbyImageData(
            transformOptions: {
              duotone: {
                highlight: "#ffffff"
                shadow: "#b1976b"
              }
            }
          )
        }
      }
    }
  `)

  const cardBG = getImage(backgroundImage.file);
  const stackedCardBG = [`linear-gradient(rgba(255,255,255,.5),rgba(255,255,255,.5))`, cardBG];
  const stackedHoverCardBG = [`linear-gradient(rgba(255,255,255,.65),rgba(255,255,255,.65))`, cardBG];

  const stackedCardBGStyle = {
    position: 'absolute'
  };

  return (
    <div className="results--column">
      <div className="results--thumb">
        <div className="results--image">
          <div className="results--image-frame">
            <div className="caseCard">
              <div className="caseCard--title">
                <h3>
                  {award}
                </h3>
                <div className="caseCard--title__caption">
                  {category}
                </div>
              </div>
              <BgImage className="caseCard--hover" image={stackedHoverCardBG} preserveStackingContext style={stackedCardBGStyle}>
                <p>
                  {narrative}
                </p>
              </BgImage>
              <BgImage className="caseCard--background" image={stackedCardBG} preserveStackingContext style={stackedCardBGStyle}>
                {/* Background Image */}
                <div className="caseCard--background__title">
                  <h3>
                    {award}
                  </h3>
                  <div className="caseCard--background__title__caption">
                    {category}
                  </div>
                </div>
              </BgImage>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
