import { graphql } from 'gatsby'
import React from 'react'
import Seo from '../../components/layout/SEO';
import Results from '../../components/elements/Results';
import ResultsThumb from '../../components/elements/ResultsThumb';
import Flowline from '../../components/layout/Flowline';
import Layout from '../../components/layout/LayoutInner';
import InnerHead from '../../components/layout/InnerHead';

export default function index({ data }) {

  return (
    <Layout>
      <Seo
        title='Our Case Results'
        canonical='results/'
      />
      <InnerHead title="Dreyer Law Case Results" caption="Past Successes" />
      <Flowline />
      <main className="results-page">
        <div className="container">
          <Results>
            {data.allResultsJson.edges.map(({ node }) => {
              return <ResultsThumb data={node} key={node.id} />
            })}
          </Results>
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    allResultsJson {
      edges {
        node {
          award
          category
          id
          narrative
        }
      }
    }
  }
`
